import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/psw/src/components/searchHighlighterProvider/SearchHighlighterProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/psw/src/style/index.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@mui+material-nextjs@5.15.11_@mui+material@5.15.19_@types+react@18.3.3_next@14.2.5_react@18.3.1/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/RecklessNeue-Regular.woff2\",\"display\":\"swap\",\"variable\":\"--f-serif\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"recklessNeue\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--f-sans\",\"fallback\":[\"sans-serif\"],\"src\":[{\"path\":\"./fonts/active_grotesk_300.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_500.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"activeGrotesk\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.6_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"src/app/[lng]/[tenant]/[...slugs]/_fonts.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--f-serif\",\"fallback\":[\"sans-serif\"],\"src\":[{\"path\":\"./fonts/active_grotesk_300.woff2\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_400.woff2\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_500.woff2\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"./fonts/active_grotesk_600.woff2\",\"weight\":\"600\",\"style\":\"normal\"}]}],\"variableName\":\"activeGroteskHeadings\"}");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-web/src/cssVariables/CssVariables.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui-web/src/muiTheme/MuiTheme.tsx");
