'use client';

import React from 'react';

import { Cms_Tenant } from '@lib/services/cms';
import { Tenant_ColorPalette, tenantService } from '@lib/services/tenant';
import { hexToRgb } from '@lib/utils';

interface CssVariablesProps {
  tenant?: Cms_Tenant;
  defaultColors: Tenant_ColorPalette;
}

const CssVariables: React.FC<CssVariablesProps> = ({ tenant, defaultColors }) => {
  const colors = tenantService.getColorTheme(defaultColors, tenant);

  return (
    <style id="variables-css">
      {`
      :root {
        --c-primary: ${colors.primary};
        --c-primary-light: ${colors.primaryLight};
        --c-primary-dark: ${colors.primaryDark};
        --c-primary-a80: ${hexToRgb(colors.primary, 0.8)};
        --c-primary-a60: ${hexToRgb(colors.primary, 0.6)};
        --c-primary-a40: ${hexToRgb(colors.primary, 0.4)};
        --c-primary-a20: ${hexToRgb(colors.primary, 0.2)};
        --c-primary-a10: ${hexToRgb(colors.primary, 0.1)};

        --c-black: ${colors.black};
        --c-white: ${colors.white};
        --c-grey-bg: ${colors.greyBg};
        --c-grey-light: ${colors.greyLight};
        --c-grey-medium: ${colors.greyMedium};

        --c-salmon: ${colors.salmon};
        --c-orange: ${colors.organge};
        --c-yellow: ${colors.yellow};

        --c-error: ${colors.error};
      }
    `}
    </style>
  );
};

export { CssVariables };
export type { CssVariablesProps };
